import './bootstrap';

// import Alpine from 'alpinejs';
import '../js/layout.js';

function isWebp() {
    function testWebp(callback) {
        let webp = new Image();
        webp.onload = webp.onerror = function () {
            callback(webp.height == 2);
        };
        webp.src =
            "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
    }
    testWebp(function (support) {
        let className = support === true ? "webp" : "no-webp";
        document.documentElement.classList.add(className);
    });
}

isWebp();

function myProfileDropdown() {
    const dropdownBtn = document.getElementById("btn");
    const dropdownMenu = document.getElementById("dropdown");
    // const toggleArrow = document.getElementById("arrow");

    // Toggle dropdown function
    const toggleDropdown = function () {
        if(dropdownMenu !== null && dropdownMenu !== undefined) {
            dropdownMenu.classList.toggle("show");
        }
        // toggleArrow.classList.toggle("arrow");
    };

    if(dropdownBtn !== null && dropdownBtn !== undefined){
        // Toggle dropdown open/close when dropdown button is clicked
        dropdownBtn.addEventListener("click", function (e) {
            e.stopPropagation();
            toggleDropdown();
        });
    }

    // Close dropdown when dom element is clicked
    document.documentElement.addEventListener("click", function () {
        if(dropdownMenu !== null && dropdownMenu !== undefined) {
            if (dropdownMenu.classList.contains("show")) {
                toggleDropdown();
            }
        }
    });
}
document.addEventListener("DOMContentLoaded", myProfileDropdown);

// function phoneNumbers() {
//     $(function () {
//         $("#country").change(function () {
//             let countryCode = $(this).find("option:selected").data("country-code");
//             let value = "+" + $(this).val();
//             $("#txtPhone").val(value).intlTelInput("setCountry", countryCode);
//         });
//
//         var code = "+49";
//         $("#txtPhone").val(code).intlTelInput();
//     });
// }
// document.addEventListener("DOMContentLoaded", phoneNumbers);

function accordion() {
    let acc = document.getElementsByClassName("accordion");
    let i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function () {
            this.classList.toggle("active");
            let panel = this.nextElementSibling;
            if (panel.style.display === "block") {
                panel.style.display = "none";
            } else {
                panel.style.display = "block";
            }
        });
    }
}
document.addEventListener("DOMContentLoaded", accordion);

function selectStep() {
    const mainStep = $("#stepMain");

    let validationTrue = true;


    $("#stepMain .next-button").on("click", function () {
        let select = $(".nice-select.anfrage-choice .option.selected");
        let selectValue = $(select).attr("data-value");

        // let selectValue = $(".js-form-select").val();
        if (mainStep.find("input.input-error").length === 0) {
            let nextStep = $(".step" + "[data-select=" + selectValue + "]");
            // console.log(nextStep);
            $(mainStep).removeClass("active");
            $(nextStep).addClass("active");
        } else {
            let stepInput = $(this).closest(".step").find("input");
            stepInput.each(function () {
                let inputName = $(this).attr("name");
                let $this = $(this);
                if (inputName === "email") {
                    validateEmailInput($this);
                } else if (
                    inputName === "Name" ||
                    inputName === "Lastname" ||
                    inputName === "street" ||
                    inputName === "postcode" ||
                    inputName === "f_name" ||
                    inputName === "l_name" ||
                    inputName === "house_number" ||
                    inputName === "phone-input" ||
                    inputName === "city"
                ) {
                    validateInput($this);
                } else if (inputName === "Number") {
                    validateInputSecond($this);
                    // validateRadio($this);
                }
            });
            if (
                mainStep.find("input.input-error").length === 0

            ) {
                let nextStep = $(".step" + "[data-select=" + selectValue + "]");
                $(mainStep).removeClass("active");
                $(nextStep).addClass("active");
            }
        }

    });

    $(document).on('click', ".previous-button", function (){

        if($(this).hasClass('button-to-main')){
            $(mainStep).addClass("active");
            $(this).closest(".step").removeClass("active");
        } else{
            let prevStep = $(this).parents('.step').prev()
            $(prevStep).addClass("active");
            $(this).closest(".step").removeClass("active");
        }
    })

    ////////////////////////////////////////////////
    $(".next-button__steps").on("click", function (e) {
        let validationTrue = true;
        let currentStepNow = $(this).closest(".step");

        if (currentStepNow.find("input[type=radio]").length > 0) {
            // console.log(currentStepNow.find("input[type=radio]:checked"));
            if (currentStepNow.find("input[type=radio]:checked").length === 0) {
                // Якщо не було вибрано радіокнопки
                e.preventDefault();
                validationTrue = false;
                setError2(currentStepNow, "Wähle eine Kategorie!");
            } else {
                validationTrue = true;
                setSuccess2(currentStepNow);
            }
        }

        if (!validationTrue) {
            e.preventDefault();
        } else {
            $(this).closest(".step").removeClass("active");
            $(this).closest(".step").next().addClass("active");
            currentStepNow.removeClass("error");
            currentStepNow.next().addClass("active");
        }
    });

    const setError2 = (element, message) => {
        element.addClass("error"); // Додає клас помилки до елемента, який потребує вибору радіокнопки
        element.find(".error-message").text(message); // Встановлює текст помилки
        element.find(".error-message").show(); // Показує помилку
    };

    const setSuccess2 = (element) => {
        element.removeClass("error"); // Видаляє клас помилки
        element.find(".error-message").hide(); // Ховає помилку
    };

    ////////////////////////////////////////////////

    $(".previous-button__steps").on("click", function () {
        $(this).closest(".step").removeClass("active");
        $(this).closest(".step").prev().addClass("active");
    });

    $(".step[data-select=direct] .next-button__steps").on("click", function (e) {
        let stepInput = $(this).closest(".step").find("input");
        let currentStep = $(this).closest(".step");
        stepInput.each(function () {
            let inputName = $(this).attr("name");
            let $this = $(this);
            if (inputName === "account-holder" || inputName === "bank" || inputName === "credit") {
                validateInput($this);
            } else if (inputName === "bic") {
                validateBICInput($this);
            }
        });
        if (currentStep.find("input.input-error").length > 0) {
            e.preventDefault();
            validationTrue = false;
        } else {
            validationTrue = true;
        }

        if (!validationTrue) {
            e.preventDefault();
        } else {
            $(this).closest(".step").removeClass("active");
            $(this).closest(".step").next().addClass("active");
        }
    });

    $(".step[data-select=request] .form__button-submit").on("click", function (e) {

        let checkboxes = $(this).closest(".step").find("input[type=checkbox]")

        $(checkboxes).each((index, checkboxInput) => {
            if($(checkboxInput).prop('checked') != true){
                e.preventDefault();
            }
        })
    });
    const setError = (element, message) => {
        const inputControl = element.closest(".anfrage-form__item");
        const errorMessage = inputControl.find(".error-message");
        errorMessage.text(message);
        errorMessage.css("display", "block");
        element.addClass("input-error");
        element.removeClass("input-success");
    };
    const setSuccess = (element) => {
        const inputControl = element.closest(".anfrage-form__item");
        const errorMessage = inputControl.find(".error-message");
        errorMessage.text("");
        errorMessage.css("display", "none");
        element.removeClass("input-error");
        element.addClass("input-success");
    };
    function validateInput($this) {
        if ($this.val() === "") {
            setError($this, "Feld ist erforderlich");
            return false;
        } else if ($this.val().trim().length < 2) {
            setError($this, "Hier scheint etwas nicht zu stimmen.");
            return false;
        } else {
            setSuccess($this);
            return true;
        }
    }
    function validateInputSecond($this) {
        if ($this.val() === "") {
            setError($this, "Feld ist erforderlich");
            return false;
        } else {
            setSuccess($this);
            return true;
        }
    }
    function validateRadio($this) {
        if ($this.val() === "") {
            setError($this, "Feld ist erforderlich");
            return false;
        } else {
            setSuccess($this);
            return true;
        }
    }
    function validateEmailInput($this) {
        const emailPattern =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const isEmailValid = emailPattern.test($this.val());
        if (!isEmailValid) {
            setError($this, "Geben Sie eine gültige E-Mail-Adresse ein, z. B. mail@me.com");
            return false;
        } else {
            setSuccess($this);
            return true;
        }
    }
    function validateBICInput($this) {
        const phonePattern = /^[+]?\d+$/;
        const isPhoneValid = phonePattern.test($this.val());
        if (!isPhoneValid) {
            setError($this, "Nur Zahlen eingeben");
            return false;
        } else if ($this.val() === "") {
            setError($this, "Feld ist erforderlich");
            return false;
        }
        {
            setSuccess($this);
            return true;
        }
    }
}
document.addEventListener("DOMContentLoaded", selectStep);

function webFlowFunction() {
    //Show name dashboard link on mobile
    $(window).on("load", function () {
        const tooltipLinkCurrent = $(".nav-mobile_link.w--current .tooltip");
        $(".nav-mobile_link .tooltip:not(.nav-mobile_link.w--current .tooltip)").css("display", "none");
        tooltipLinkCurrent.addClass("active");
        setTimeout(function () {
            tooltipLinkCurrent.removeClass("active");
        }, 2000);
        setTimeout(function () {
            tooltipLinkCurrent.css("display", "none");
        }, 3000);
    });
    $(document).ready(function () {
        $(".residents-table_item").each(function (index, value) {
            const airtableId = $(this).find(".airtable-id").text();
            $(this).attr("data-airtable-id", airtableId);
        });
        $(".doc_dropdown").each(function (index, value) {
            const counter = $(this).find(".doc_counter").text($(this).find(".doc_list").children().length);
        });

        let selects = $('select')

        if($(selects).length !== 0){
            $(selects).each((key, select) => {
                if(!$(select).hasClass('js-select2')){
                    $(select).niceSelect();
                }
            })
        }

        $(".w-webflow-badge").removeClass("w-webflow-badge").empty();
    });


    $(document).on('submit', '#Form-Add-suggestions', function (){
        setTimeout(function () {

            // console.log($('#Form-Add-suggestions').length)
            // if($('#Form-Add-suggestions').length !== 0){
            //     $('#Form-Add-suggestions').find('.nice-select .current').text('Categories');
            //     $('#Form-Add-suggestions').find('textarea').val('');
            // }
        }, 2000);
    })
    const $html = $(document.documentElement);
    $(".popup-open").click(function () {
        $html.css("overflow", "hidden");
    });
    $(".cookie-open").click(function () {
        $html.css("overflow", "hidden");
    });
    $(".close-popup").click(function () {
        $html.css("overflow", "");
    });
    $(document).mouseup(function (e) {
        const contactUs = $(".popup-contact-us");
        if (!contactUs.is(e.target) && contactUs.has(e.target).length === 0) {
            $(".popup-wrapper").fadeOut(300);
            $html.css("overflow", "");
        }
    });
    $("img.img-svg").each(function () {
        var $img = $(this);
        var imgClass = $img.attr("class");
        var imgURL = $img.attr("src");
        $.get(
            imgURL,
            function (data) {
                var $svg = $(data).find("svg");
                if (typeof imgClass !== "undefined") {
                    $svg = $svg.attr("class", imgClass + " replaced-svg");
                }
                $svg = $svg.removeAttr("xmlns:a");
                if (!$svg.attr("viewBox") && $svg.attr("height") && $svg.attr("width")) {
                    $svg.attr("viewBox", "0 0 " + $svg.attr("height") + " " + $svg.attr("width"));
                }
                $img.replaceWith($svg);
            },
            "xml"
        );
    });
    if ($(".anfrage-form").length) {
        var input = document.querySelector("#phone"),
            dialCode = document.querySelector(".dialCode"),
            errorMsg = document.querySelector("#error-msg"),
            validMsg = document.querySelector("#valid-msg");
        // var iti = intlTelInput(input, {
        //     initialCountry: "DE",
        //     placeholderNumberType: "FIXED_LINE",
        // });
        // var updateInputValue = function (event) {
        //     dialCode.value = "+" + iti.getSelectedCountryData().dialCode;
        // };
        // input.addEventListener("input", updateInputValue, false);
        // input.addEventListener("countrychange", updateInputValue, false);
        // var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
        // var reset = function () {
        //     input.classList.remove("error");
        //     errorMsg.innerHTML = "";
        //     errorMsg.classList.add("hide");
        //     validMsg.classList.add("hide");
        // };
        // input.addEventListener("blur", function () {
        //     reset();
        //     if (input.value.trim()) {
        //         if (iti.isValidNumber()) {
        //             validMsg.classList.remove("hide");
        //         } else {
        //             input.classList.add("error");
        //             var errorCode = iti.getValidationError();
        //             errorMsg.innerHTML = errorMap[errorCode];
        //             errorMsg.classList.remove("hide");
        //         }
        //     }
        // });
        // input.addEventListener("change", reset);
        // input.addEventListener("keyup", reset);
    }
}
document.addEventListener("DOMContentLoaded", webFlowFunction);

function typeRadio() {
    $(document).ready(function () {
        $('input[type="radio"]').click(function () {
            $('input[type="radio"]').removeClass("w--redirected-checked");
            $(this).addClass("w--redirected-checked");
        });
    });
}
document.addEventListener("DOMContentLoaded", typeRadio);

// function SelectLang() {
//     let selectContainer = document.querySelector(".dashboard_lang");
//     let select = document.querySelector(".select");
//     let input = document.getElementById("input");
//     let options = document.querySelectorAll(".dashboard_lang .option");
//
//     select.onclick = () => {
//         selectContainer.classList.toggle("active");
//     };
//
//     options.forEach((e) => {
//         e.addEventListener("click", () => {
//             input.value = e.innerText;
//             selectContainer.classList.remove("active");
//             options.forEach((e) => {
//                 e.classList.remove("selected");
//             });
//             e.classList.add("selected");
//         });
//     });
// }
// document.addEventListener("DOMContentLoaded", SelectLang);

function uploadFile() {
    let $fileInput = $(".file-input");
    let $droparea = $(".file-drop-area");
    let $fileInputText = $($fileInput).attr('data-text')

    // highlight drag area
    $fileInput.on("dragenter focus click", function () {
        $droparea.addClass("is-active");
    });

    // back to normal state
    $fileInput.on("dragleave blur drop", function () {
        $droparea.removeClass("is-active");
    });

    // change inner text
    $fileInput.on("change", function () {
        var filesCount = $(this)[0].files.length;
        var $textContainer = $(this).prev();

        if (filesCount === 1) {
            // if single file is selected, show file name
            var fileName = $(this).val().split("\\").pop();
            $textContainer.text(fileName);
        } else {
            // otherwise show number of files
            $textContainer.text(filesCount + $fileInputText);
        }
    });
}
document.addEventListener("DOMContentLoaded", uploadFile);


//Show hide attached files
$(document).ready(function() {
    $('.show-attached').click(function() {
        var attachedWrapper = $(this).siblings('.newsfeed_attached-wrapper');
        var showAttachedArrow = $(this).find('.show-attached__arrow');

        if (attachedWrapper.css('display') === 'none') {
            attachedWrapper.css('display', 'block');
            showAttachedArrow.css('transform', 'rotate(180deg)');
        } else {
            attachedWrapper.css('display', 'none');
            showAttachedArrow.css('transform', 'rotate(0deg)');
        }
    });
});


// window.Alpine = Alpine;
//
// Alpine.start();
